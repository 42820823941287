import React from "react"

import SEO from "../components/seo"
import PageHeadline from "../components/pageHeadline/PageHeadline.component"
import ProjectsContainer from "../components/projectsContainer/ProjectsContainer.component"

const Projects = () => {
  return (
    <>
      <SEO title="Projects" />
      <PageHeadline headline1="Our" headline2="Projects" />
      <ProjectsContainer skipRotation={false} />
    </>
  )
}

export default Projects
